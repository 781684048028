<template>
  <div id="ask_terms">
    <h1>1:1문의 이용약관</h1>
    1. 수집 정보 <br />
    ㈜데이터유니버스는 이용자의 원활한 고객상담 및 불만처리를 위하여 아래와 같은 정보를 수집 합니다.<br />
    <br />
    * 수집 정보 : 이름, 연락처, 이메일 주소, 고객의 문의/답변 내용에 포함된 정보<br />
    <br />
    2. 수집 및 이용 목적<br />
    수집된 정보는 고객문의 및 상담요청에 대하여 회신을 하거나, 상담을 위한 서비스 이용기록 조회를 목적으로
    활용합니다.<br />
    <br />
    3. 보관 및 이용 기간<br />
    이용자의 정보는 문의 처리 이후 3년간 보관 후 파기됩니다.<br />
  </div>
</template>

<script>
export default {
  name: "Ask",
}
</script>
